// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
// import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import MoreHoriz from '@mui/icons-material/MoreHoriz';

// ------FILE MODULES---------------------------------------------------------
import TableCell from '../../shared/Tables/TableCell.component';
import Status from '../Status/Status.component';
import PresentationRequestButtons from '../Dropdowns/PresentationRequestButtons';
import Hider from '../../shared/Hider/Hider.component';
import { PAGE_ROUTES } from "#constants";
import { DigitalSignatureRequestsSentDetail } from '../../../config/routes.config';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE_PREFIX = 'presentation-request-row';
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_PRESENTATION_REQUEST = { storedJwt: { status: '' }, decoded: { pr: { type: [] } } };
const DEFAULT_HIDE_SIGN_BUTTON = false;
const DEFAULT_HIDE_STATUS = false;
const DEFAULT_HIDE_NAME = false;
const DEFAULT_HIDE_ISSUER = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_TYPE = false;

// ---------------------------------------------------------------------------
const mockData = {
  
    storedJwt: { status: 'Firmado' },
    decoded: {
      cdiName: 'Acceso Instalaciones',
      customers: [
        {customer: { businessName: 'Proveedor 1' } },
      ],
      issuerName: { businessName: 'Proveedor 1' }, 
      exp: '17 dic 2024'
   
  }}
  
// EXPORTS
// ---------------------------------------------------------------------------
export default function DigitalSignaturePresentationRequestRow(props) {
  const navigate = useNavigate();

  const presentationRequest = mockData 
  let customer = presentationRequest.decoded.customers?.[0]?.customer?.businessName
  const endDate = presentationRequest.decoded.exp
  const status = presentationRequest.storedJwt.status;
  const admin = props.admin || false;
  const disableOnClick = props.disableOnClick || false;
  const name = presentationRequest.decoded.cdiName;
  const issuer = presentationRequest.decoded.issuerName
    ? presentationRequest.decoded.issuerName.businessName
    : presentationRequest.decoded.iss;

  let onClick = () => {};
  let cursor = 'unset';

  if (!disableOnClick) {
    onClick = () => (admin) ? navigate(`${DigitalSignatureRequestsSentDetail}`) : navigate(`${DigitalSignatureRequestsSentDetail}`)
    cursor = 'pointer';
  }

  return (
    <TableRow
    sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA', cursor: 'pointer' }}
    onClick={onClick}
  >
    <Hider isHidden={props.hideName}>
      <TableCell>{name}</TableCell>
    </Hider>
    <Hider isHidden={props.hideIssuer}>
      <TableCell>{issuer}</TableCell>
    </Hider>
    <Hider isHidden={!props.hideIssuer}>
      <TableCell>{customer}</TableCell>
    </Hider>
    <TableCell>{endDate}</TableCell>
    <TableCell>{status}</TableCell>
    <Hider isHidden={props.hideActions}>
      <TableCell>
        <IconButton onClick={(event) => event.stopPropagation()}>
          <MoreHoriz />
        </IconButton>
      </TableCell>
    </Hider>
  </TableRow>
  );
}