// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

// ------FILE MODULES---------------------------------------------------------

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreatePRTFormAdHoc(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const title = props.title;
  const setTitle = props.setTitle;
  const description = props.description;
  const setDescription = props.setDescription;
  const onCancelOrExit = props.onCancelOrExit;
  const onNextClicked = props.onNextClicked;

  const isFormValid = () => {
    return title && description;
  };

  return (
    <Dialog open={isOpen} onClose={onCancelOrExit}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('admin-presentations-page-form-adhoc-popup-title')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <Grid container justifyContent='center' alignItems='center' direction='column'>
          <Grid item='true' paddingTop='10px' alignItems='center'>
            <Typography fontWeight='bold' fontSize='0.938rem'>
              {translate('admin-presentations-page-form-adhoc-popup-subtitle')}
            </Typography>
          </Grid>

          <Box lineHeight="1.5" sx={{textAlign: 'center', maxWidth: '400px'}} >
            {translate('admin-presentations-page-form-adhoc-popup-text')}
          </Box>
          <Grid item='true' paddingTop='10px'>
            <Grid container direction='column' justifyContent='center' alignItems='stretch'>
              <Grid item='true'>
                <Typography fontWeight='bold' fontSize='0.938rem'>
                  {translate('admin-presentations-page-form-adhoc-popup-input')}
                </Typography>
              </Grid>
              <TextField
                required
                value={title || ''}
                placeholder={translate('admin-presentations-page-form-adhoc-popup-input')}
                onChange={(event) => setTitle(event.target.value)}
                sx={{
                  height: '2.5rem',
                  width: '22.125rem',
                  backgroundColor: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '0.313rem',
                  fontSize: '0.938rem',
                  '.MuiInputBase-root': {
                    height: '2.5rem',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5'
                    }
                  },
                  '&.Mui-focused': {
                    border: 'none'
                  },
                  '&.MuiOutlinedInput-root:hover': {
                    border: 'none'
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item='true' paddingTop='10px'>
            <Grid container direction='column' justifyContent='center' alignItems='stretch'>
              <Grid item='true'>
                <Typography fontWeight='bold' fontSize='0.938rem'>
                  {translate('admin-presentations-page-form-adhoc-popup-description')}
                </Typography>
              </Grid>
              <TextField
                required
                value={description || ''}
                placeholder={translate('admin-presentations-page-form-adhoc-popup-description')}
                onChange={(event) => setDescription(event.target.value)}
                multiline
                sx={{
                  width: '22.125rem',
                  backgroundColor: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '0.313rem',
                  fontSize: '0.938rem',
                  '.MuiInputBase-root': {
                    '&:hover': {
                      border: '1.25px solid #E5E5E5'
                    }
                  },
                  '&.Mui-focused': {
                    border: 'none'
                  },
                  '&.MuiOutlinedInput-root:hover': {
                    border: 'none'
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item='true' padding='25px'>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
              <Grid item='true'>
                <Button
                  onClick={onCancelOrExit}
                  color='secondary'
                  variant='contained'
                  sx={{
                    border: '1px solid #E5E5E5',
                    fontSize: '0.938rem',
                    fontWeight: '600',
                    width: '130px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5',
                      boxShadow: 'none'
                    }
                  }}>
                  {translate('admin-presentations-page-form-adhoc-popup-button-cancel')}
                </Button>
              </Grid>
              <Grid item='true'>
                <Button
                  onClick={onCancelOrExit}
                  color='secondary'
                  variant='contained'
                  disabled={!isFormValid()}
                  sx={{
                    border: '1px solid #E5E5E5',
                    fontSize: '0.938rem',
                    fontWeight: '600',
                    width: '130px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5',
                      boxShadow: 'none'
                    }
                  }}>
                  {translate('admin-presentations-page-form-adhoc-popup-button-next')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
