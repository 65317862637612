// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';

// ------ FILE MODULES ---------------------------------------------------------
import {
  clearPresentationRequestTemplatesCache,
  fetchPRTAsync,
  selectPresentationRequestTemplates,
  selectPresentationRequestTemplatesCount,
  selectPresentationRequestTemplatesPage,
  selectPresentationRequestTemplatesRowsPerPage,
  selectPresentationRequestTemplatesState
} from '#reducers/presentationRequestTemplates';

import { REDUCER_STATUS } from '#constants';
import config from '#config';

import PresentationRequestTemplateTable from '#privateComponents/Tables/PresentationRequestTemplateTable.component';
import PresentationRequestTemplateFilters from '#privateComponents/Filters/PresentationRequestTemplateFilters.component';
import CreatePRTDetails from '#privateComponents/Popups/CreatePRTDetails.component';
import CreatePRTCredentialSelection from '#privateComponents/Popups/CreatePRTCredentialSelection.component';
import CreatePRTFormAdHoc from '#privateComponents/Popups/CreatePRTFormAdHoc.component.js';

import ViewsLayout from '../../../../views/Main/MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTemplates(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const [isFormAdHocOpen, setIsFormAdHocOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isCredentialSelectionOpen, setIsCredentialSelectionOpen] = useState(false);
  const [page, setPage] = useState(useSelector(selectPresentationRequestTemplatesPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectPresentationRequestTemplatesRowsPerPage));
  const [search, setSearch] = useState(null);
  const [createDate, setCreateDate] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const dispatch = useDispatch();

  const presentationTemplates = useSelector(selectPresentationRequestTemplates);
  const presentationsState = useSelector(selectPresentationRequestTemplatesState);
  const presentationCount = useSelector(selectPresentationRequestTemplatesCount);

  const isLoading = presentationsState === REDUCER_STATUS.LOADING;
  const needsReload = presentationsState === REDUCER_STATUS.NEEDS_REFRESH;

  if (needsReload) {
    dispatch(
      fetchPRTAsync({
        page,
        rowsPerPage,
        search,
        createDate: Math.round(createDate / 1000)
      })
    );
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearPresentationRequestTemplatesCache());
  };

  const onCancelOrExit = () => {
    setTitle(null);
    setDescription(null);
    setIsFormAdHocOpen(false)
    setIsDetailsOpen(false);
    setIsCredentialSelectionOpen(false);
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearPresentationRequestTemplatesCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearPresentationRequestTemplatesCache());
  };

  return (
    <ViewsLayout
      pageTitle={translate('admin-presentations-page-title')}
      buttons={[
        {
          onClick: () => setIsFormAdHocOpen(true),
          text: translate('admin-presentations-page-form-adhoc-button')
        },
        {
          onClick: () => setIsDetailsOpen(true),
          text: translate('admin-presentations-page-create-button')
        },
        {
          onClick: useLinkClickHandler(`${config.constants.PAGE_ROUTES.AdminPresentationRequestCreation}`),
          text: translate('admin-presentation-request-create-button')
        },
      ]}
      layoutSize={layoutSize}>
      <CreatePRTDetails
        translate={translate}
        isOpen={isDetailsOpen}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        onCancelOrExit={onCancelOrExit}
        onNextClicked={() => {
          setIsDetailsOpen(false);
          setIsCredentialSelectionOpen(true);
        }}
      />

      <CreatePRTFormAdHoc
        translate={translate}
        isOpen={isFormAdHocOpen}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        onCancelOrExit={onCancelOrExit}
        onNextClicked={() => {
          setIsDetailsOpen(false);
          setIsCredentialSelectionOpen(true);
        }}
      />

      <CreatePRTCredentialSelection
        translate={translate}
        isOpen={isCredentialSelectionOpen}
        onCancelOrExit={onCancelOrExit}
        title={title}
        description={description}
      />
      
      <Grid item='true'>
        <PresentationRequestTemplateFilters
          translate={translate}
          createDate={createDate}
          setCreateDate={setCreateDate}
          search={search}
          setSearch={setSearch}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <PresentationRequestTemplateTable
        translate={translate}
        isLoading={isLoading}
        presentationRequestTemplates={presentationTemplates}
        presentationCount={presentationCount}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideFile
      />
    </ViewsLayout>
  );
}
