// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { NETWORK_IDS, TECHNOLOGIES, TRUST_FRAMEWORKS } from '#constants';
import { isOk, post } from '../../../helpers/axios.helper';
import { setError } from '../../../store/reducers/error';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';


// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function SendPresentationPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const formSent = props.formSent;
  const cdi = props.cdi;
  const presentations = props.presentations;

  const [formSendState, setFormSendState] = useState(false);
  const [isOpenDisclaim, setIsOpenDisclaim] = useState(false);

  const presentationRequest = props.presentationRequest;
  console.log(presentationRequest)

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClose2 = () => {
    setIsOpenDisclaim(false);
  };

  const isFormValid = () => {
    return presentations && presentations.length > 0
  };

  const submitForm = async (event) => {
    event.preventDefault();

   /* const keys = Object.keys(selectionStatus);
    const signedTokens = keys
      .map((key) => selectionStatus[key])
      .filter((credential) => credential)
      .map((credential) => credential.storedJwt.signedToken);*/
    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid: 'default',
      sub: presentationRequest.decoded.iss,
      cbu: presentationRequest.decoded.cbu,
      cdi: presentationRequest.decoded.cdi,
      cdiName: presentationRequest.decoded.cdiName,
      validFrom: presentationRequest.decoded.nbf,
      validUntil: presentationRequest.decoded.exp,
      presentationContext: 'https://www.w3.org/2018/credentials/presentation/examples/v1',
      presentationRequestJti: presentationRequest.storedJwt.jti,
      credentials: presentations.map((presentation) => presentation.storedJwt.signedToken)
    };

    setFormSendState(true);
    const response = await post('/dit/presentation', form);

    if (!isOk(response.status)) {
      dispatch(setError(response));
    }

    setFormSendState(false);
    formSent();
    handleClose();
    setIsOpenDisclaim(true);
  };

  return (
    <Grid>
    <Dialog open={isOpen} onClose={handleClose} maxWidth='md'>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('presentation-sign-popup-title')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader isLoading={formSendState} text={translate('presentation-sign-popup-loading-signing')}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item='true' paddingBottom={1}>
              <Typography>{translate('presentation-sign-popup-disclaimer')}</Typography>
            </Grid>
            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-sign-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-sign-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
    <Dialog open={isOpenDisclaim} onClose={handleClose2} maxWidth='md'>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('pr-details-sign')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose2}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-sign-popup-close-button')}
                  </Button>
                </Grid>
              </Grid>
      </DialogContent>
    </Dialog>
    </Grid>
  );
}
