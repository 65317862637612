// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// ------FILE MODULES---------------------------------------------------------
import { DigitalSignatureCatalog } from './../../../config/routes.config';
import { Link } from 'react-router-dom';
import { PAGE_ROUTES } from '#constants';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';
import mockedData from '../../../config/mockedData.config';
import { useState } from 'react';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreateSignRequestFormDetails(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const title = props.title;
  const setTitle = props.setTitle;
  const description = props.description;
  const setDescription = props.setDescription;
  const onCancelOrExit = props.onCancelOrExit;
  // const onNextClicked = props.onNextClicked;
  const catalogMockDS = mockedData.digitalSignatureCatalog //add mocked data
  const [selectedOptionDropdown, setSelectedOptionDropdown] = useState(); 

  const isFormValid = () => {
    return title && description;
  };

  return (
    // --------------------CreateSignRequestFormDetails popup form button Create Config Digital Signature
    <Dialog open={isOpen} onClose={onCancelOrExit}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('admin-prt-popup-create-template')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <Grid container justifyContent='center' alignItems='center' direction='column'>
          <Grid item='true' paddingTop='10px'>
            <Typography fontWeight='bold' fontSize='0.938rem'>
              {translate('admin-prt-popup-details-title')}
            </Typography>
          </Grid>
          <Grid item='true' paddingTop='10px'>
            <Grid container direction='column' justifyContent='center'>
              <Grid item='true'>
                <Typography fontWeight='bold' fontSize='0.938rem'>
                  {translate('admin-prt-popup-title')}
                </Typography>
              </Grid>
              <TextField
                required
                value={title || ''}
                placeholder={translate('admin-prt-popup-title-placeholder')}
                onChange={(event) => setTitle(event.target.value)}
                sx={{
                  height: '2.5rem',
                  width: '22.125rem',
                  backgroundColor: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '0.313rem',
                  fontSize: '0.938rem',
                  '.MuiInputBase-root': {
                    height: '2.5rem',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5'
                    }
                  },
                  '&.Mui-focused': {
                    border: 'none'
                  },
                  '&.MuiOutlinedInput-root:hover': {
                    border: 'none'
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item='true' paddingTop='10px' paddingLeft='10px' >
              <Grid item='true'>
                <Typography fontWeight='bold' fontSize='0.938rem'>
                  {translate('admin-prt-popup-description')}
                </Typography>
              </Grid>
              <TextField
                required
                value={description || ''}
                placeholder={translate('admin-prt-popup-description-placeholder')}
                onChange={(event) => setDescription(event.target.value)}
                multiline
                sx={{
                  width: '22.125rem',
                  backgroundColor: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '0.313rem',
                  fontSize: '0.938rem',
                  '.MuiInputBase-root': {
                    '&:hover': {
                      border: '1.25px solid #E5E5E5'
                    }
                  },
                  '&.Mui-focused': {
                    border: 'none'
                  },
                  '&.MuiOutlinedInput-root:hover': {
                    border: 'none'
                  }
                }}
              />
              <Typography fontWeight='bold' fontSize='0.938rem' paddingTop='10px' >
                  {translate('Opciones del Catálogo')}
                </Typography>
              <Dropdown
              label="Opciones del Catálogo"
              options={catalogMockDS || []}
              getOptionLabel={(option) => option.name}
              value={selectedOptionDropdown}
              onChange={(catalogItem) => {
                setSelectedOptionDropdown(catalogItem)
                console.log('Seleccionaste:', catalogItem)
              }}
              selectStyle={{
                width: '22.25rem',
                borderRadius: '5px',
                fontSize: '0.938rem'
              }}
            />
          </Grid>
        </Grid>

         

        <Grid item='true' padding='25px'>
          <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
            <Grid item='true'>
              <Button
                  onClick={onCancelOrExit}
                  color='secondary'
                  variant='contained'
                  sx={{
                    border: '1px solid #E5E5E5',
                    fontSize: '0.938rem',
                    fontWeight: '600',
                    width: '130px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5',
                      boxShadow: 'none'
                    }
                  }}>
                  {translate('admin-prt-popup-cancel-button')}
              </Button>
            </Grid>
            <Grid item='true'>
              <Button
                  onClick={onCancelOrExit}
                  color='secondary'
                  variant='contained'
                  disabled={!isFormValid()}
                  sx={{
                    border: '1px solid #E5E5E5',
                    fontSize: '0.938rem',
                    fontWeight: '600',
                    width: '130px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5',
                      boxShadow: 'none'
                    }
                  }}>
                  {translate('admin-prt-popup-next-button')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
