// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import PresentationRequestRow from '../Rows/DigitalSignatureSignRequestsRow.component';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestTable.component.css';
import { DigitalSignatureSignRequest } from '../../../config/routes.config';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_HIDE_PAGINATION = true;
const DEFAULT_HIDE_ISSUER = false;
const DEFAULT_HIDE_NAME = false;
const DEFAULT_HIDE_SIGN_BUTTON = true;
const DEFAULT_HIDE_STATUS = true;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_TYPE = true;
const DEFAULT_PRESENTATION_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => { };
const DEFAULT_SET_ROWS_PER_PAGE = () => { };
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_PRESENTATION_REQUESTS = [];

const titleText = 'digital-signature-sign-request-table-title';
const descriptionText = 'digital-signature-sign-request-table-description';

function getHeaders(translate, hideIssuer, hideName, hideStatus, hideActions, hideType) {
  return [
    {
      text: titleText
    },
    {
      text: 'Solicitante' // Nuevo header
    },
    {
      text: descriptionText
    },
    {
      text: 'digital-signature-sign-request-table-valid-dates'
    },

    {
      text: 'presentation-request-table-actions'
    }
  ]
    .filter((header) => {
      return true;
    }).map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function DigitalSignatureSignRequestsTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideIssuer = props.hideIssuer || DEFAULT_HIDE_ISSUER;
  const hideName = props.hideName || DEFAULT_HIDE_NAME;
  const hideSignButton = props.hideSignButton || DEFAULT_HIDE_SIGN_BUTTON;
  const hideStatus = props.hideStatus || DEFAULT_HIDE_STATUS;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const hideType = props.hideType || DEFAULT_HIDE_TYPE;
  const presentationCount = props.presentationCount || DEFAULT_PRESENTATION_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;
  const buttonComponent = props.buttonComponent;
  const admin = props.admin || false;
  const disableOnClick = props.disableOnClick || false;

  let signRequestsData = [{
      decoded: {
        //Add "solicitante"
        cdiName: "Acceso Instalaciones",
        iss: "Aceptación de los documentos de acceso a las instalaciones.",
        exp: 1763164800,
        applicant: "Cepsa"
      },
      storedJwt : {
        status: 'Emitido'
      } 
    },
    {
      decoded: {
        cdiName: "Documento de seguridad",
        iss: "Compromiso de cumplimiento de normas de seguridad.",
        exp: 1763168400, // Fecha de expiración
        applicant: "Cepsa"
      },
      storedJwt: {
        status: "Pendiente"
      }
    },
    {
      decoded: {
        cdiName: "Acceso al Estacionamiento",
        iss: "Documento de autorización para el acceso al estacionamiento del Edificio C.",
        exp: 1763172000, // Fecha de expiración
        applicant: "Cepsa"
      },
      storedJwt: {
        status: "Firmado"
      }
    }]
  // let formPrs = cdiPrs.filter((pr) =>
  //   pr.decoded.pr.type.includes('Form'));
  // formPrs = formPrs.filter((pr, index) =>
  //   (formPrs.findIndex(obj => obj.decoded.cdi === pr.decoded.cdi) === index));
  // let delegatedPrs = cdiPrs.filter((pr) =>
  //   pr.decoded.pr.type.includes('Delegated'));

  // if (admin) {
  //   cdiPrs = cdiPrs.filter((pr) =>
  //     pr.decoded.pr.type.includes('Form'));
  // }
  
  // cdiPrs = cdiPrs.filter((pr, index) =>
  //   (cdiPrs.findIndex(obj => obj.decoded.cdi === pr.decoded.cdi) === index));

const headers = getHeaders(translate, hideIssuer, hideName, hideStatus, hideActions, hideType);

return (
  <Grid item='true' paddingBottom={paddingBottom}>
    <TableContainer >
      <Table>
        <TableHeader headers={headers} />
        <TableBody>
          <SpinnerLoader
            isLoading={isLoading}
            text={translate('digital-signature-sign-request-table-loading')}
            component={SingleTableRow}
            componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}>
            {signRequestsData.map((signRequest, index) => (
              <PresentationRequestRow
                key={index}
                translate={translate}
                presentationRequest={signRequest}
                buttonComponent={buttonComponent}
                hideSignButton={hideSignButton}
                hideName={hideName}
                hideIssuer={hideIssuer}
                disableOnClick={disableOnClick}
                admin={admin}
                hideStatus={hideStatus}
                hideActions={hideActions}
                hideType={hideType}
                applicant={signRequest.decoded.applicant}
              />
            ))}
          </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={headers.length} useTypography={false}>
                  <TablePagination
                    component='div'
                    count={presentationCount}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={translate(`rows-per-page`)}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
