// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLinkClickHandler, NavLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';

import Fingerprint from '@mui/icons-material/Fingerprint';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

// ------FILE MODULES---------------------------------------------------------
import { clearPresentationRequestTemplatesCache } from '../../../store/reducers/presentationRequestTemplates';
import { clearEntityPresentationRequestsCache } from '../../../store/reducers/entityPresentationRequests';
import { clearEntityCredentialRequestsCache } from '../../../store/reducers/entityCredentialRequests';
import { clearCredentialRequestsCache } from '../../../store/reducers/adminCredentialRequests';
import { clearEntityPresentationsCache } from '../../../store/reducers/entityPresentations';
import { clearEntityCredentialsCache } from '../../../store/reducers/entityCredentials';
import { clearPresentationsCache } from '../../../store/reducers/adminPresentations';
import { clearCredentialsCache } from '../../../store/reducers/adminCredentials';
import { clearCustomerCache } from '../../../store/reducers/customers';
import { clearCatalogCache } from '../../../store/reducers/catalog';

import { PAGE_ROUTES } from '#constants';

import AdminCredentialRequestsIcon from '../../shared/Icons/AdminCredentialRequestsIcon.component';
import AdminRequestHistoryIcon from '../../shared/Icons/AdminRequestHistoryIcon.component';
import AdminRequestLibraryIcon from '../../shared/Icons/AdminRequestLibraryIcon.component';
import MyCredentialRequestsIcon from '../../shared/Icons/MyCredentialRequestsIcon.component';
import ContactIndividualIcon from '../../shared/Icons/ContactIndividualIcon.component';
import AdminCredentialsIcon from '../../shared/Icons/AdminCredentialsIcon.component';
import ContactCorporateIcon from '../../shared/Icons/ContactCorporateIcon.component';
import MyPresentationsIcon from '../../shared/Icons/MyPresentationsIcon.component';
import MyCredentialsIcon from '../../shared/Icons/MyCredentialsIcon.component';
import AdminActionsIcon from '../../shared/Icons/AdminActionsIcon.component';
import MyRequestsIcon from '../../shared/Icons/MyRequestsIcon.component';
import Hider from '../../shared/Hider/Hider.component';

import config from '#config';

import './SidePanel.component.css';
import { getNotifications } from '../../../services/orchestrator';

// ---------------------------------------------------------------------------
// PRIVATE COMPONENTS
// ---------------------------------------------------------------------------
function ButtonWithIcon({ text, to, override, invalidateCaches, color, iconComponent, icon, paddingLeft, onNavigate, disabled }) {
  const handler = useLinkClickHandler(to);
  const IconComponent = iconComponent || Fragment;
  const iconToRender = icon || <IconComponent />;
  const finalColor = color || '#FFFFFFB2';
  const leftPad = paddingLeft || '0%';

  return (
    <Grid sx={{ color: finalColor, paddingLeft: leftPad }}>
    <Button
      className='side-panel-navigation-buton'
      fullWidth
      disabled={disabled} 
      sx={{
        textTransform: 'none',
        textAlign: 'left',
        '&:hover': {
          background: '#FFFFFF0A'
        },
        // Efecto visual de deshabilitado:
        ...(disabled && {
          opacity: 0.5, 
          cursor: 'not-allowed',
        }),
      }}
      onClick={(e) => {
        if (disabled) return;
        invalidateCaches(e, handler)
        if (onNavigate) {
          onNavigate();
        }
      }}>
              <NavLink className='side-panel-navigation-link' to={override || to}>
          <Grid container alignItems='center'>
            <Grid item='true' paddingRight='0.424rem' display='flex' alignItems='center'>
            {iconToRender}
          </Grid>
          <Grid item='true'>
            <Typography sx={{ fontSize: '0.875rem', color: finalColor }} variant='h6'>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </NavLink>
    </Button>
    </Grid>
  );
}

function NotificationsButton(props) {
  const translate = props.translate;
  const invalidateCaches = props.invalidateCaches;

  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const func = async () => {
      const result = await getNotifications({ isRead: false });
      setNotificationCount(result.count);
    }

    func();
    const interval = setInterval(func, config.INTERNAL.POLLING_RATE);

    return () => {
      clearInterval(interval);
    };
  }, [notificationCount, setNotificationCount]);

  const buttonProps = {
    text: translate('link-to-notification-inbox'),
    to: PAGE_ROUTES.NotificationInbox,
    invalidateCaches: invalidateCaches,
  }

  if (notificationCount <= 0) {
    buttonProps.iconComponent = ChatIcon;
  } else {
    buttonProps.icon = (
      <Badge badgeContent={notificationCount > 9 ? '9+' : notificationCount} color="error">
        <ChatIcon />
      </Badge>
    )
  }

  return <ButtonWithIcon {...buttonProps} />;
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function SidePanel(props) {
  const translate = props.translator;
  const dispatch = useDispatch();
  const floatingMenu = props.floatingMenu || false;
  const onNavigation = props.onNavigation;

  const [signatureAccordionOpen, setSignatureAccordionOpen] = useState(false)
  const [identityAccordionOpen, setIdentityAccordionOpen] = useState(false);
  const [adminAccordionOpen, setAdminAccordionOpen] = useState(false);

  const invalidateCaches = (e, handler) => {
    dispatch(clearCatalogCache());
    dispatch(clearEntityCredentialsCache());
    dispatch(clearEntityCredentialRequestsCache());
    dispatch(clearEntityPresentationsCache());
    dispatch(clearEntityPresentationRequestsCache());
    dispatch(clearCustomerCache());
    dispatch(clearCredentialsCache());
    dispatch(clearCredentialRequestsCache());
    dispatch(clearPresentationRequestTemplatesCache());
    dispatch(clearPresentationsCache());
    handler(e);
  };

  return (
    <Grid direction='column' justifyContent='flex-start' width={'300px'}>
      <Grid id={'wallet-logo'} item='true' padding='12px 12px 0 12px' display='flex' justifyContent='center' alignItems='center'>
        <img src={`${config.INTERNAL.LOGO}`} alt='Digital Wallet' width={floatingMenu ? '150px' : '250px'} />
      </Grid>
      <Grid id={'version-label'} item='true' padding='0 1px 0 30px'>
        <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFFB2' }}>
          {config.INTERNAL.VERSION}
        </Typography>
      </Grid>
      {/* 
      <ButtonWithIcon
        text={translate('link-to-digital-assets')}
        to={PAGE_ROUTES.DigitalAssets}
        invalidateCaches={invalidateCaches}
        iconComponent={SpaceDashboardOutlinedIcon}
      /> */}
      <Grid id={'first-divider'} item='true' paddingBottom='5%' paddingTop='5%' paddingLeft='1em' paddingRight='1em'>
        <Divider sx={{ borderColor: '#FFFFFF33' }} />
      </Grid>

      <Grid id={'digital-assets-button'} item='true' sx={{ color: '#FFFFFFB2', paddingLeft: '3%' }}>
        <Tooltip title={translate('coming-soon-tooltip')} slotProps={{popper: {modifiers: [{name: 'offset',options: {offset: [-20, -18],},},],},}}>
          <div style={{display: 'flex',  paddingLeft:'0.5em'}}>
            <div>
              <AccountBalanceWalletIcon/>
            </div>
            <div style={{paddingTop: '2px', paddingLeft: '7px'}}>
              <Typography sx={{ fontSize: '0.875rem', color: '#FFFFFFB2' }} variant='h6'>
                {translate('sidebar-digital-assets')}
              </Typography>
            </div>
          </div>
        </Tooltip>
      </Grid>

      <Grid id={'second-divider'} item='true' paddingBottom='5%' paddingTop='5%' paddingLeft='1em' paddingRight='1em'>
        <Divider sx={{ borderColor: '#FFFFFF33' }} />
      </Grid>

      <Hider isHidden={config.INTERNAL.THEME === 'b4e'}>
        <Grid id={'notifications-button'} item='true' paddingLeft='0.5em'>
          <NotificationsButton translate={translate} invalidateCaches={invalidateCaches} />
        </Grid>
        <Grid id={'third-divider'} item='true' paddingBottom='5%' paddingTop='5%' paddingLeft='1em' paddingRight='1em'>
          <Divider sx={{ borderColor: '#FFFFFF33' }} />
        </Grid>
      </Hider>

      <Grid item='true' sx={{ color: '#FFFFFFB2'}} paddingLeft='calc(0.5em + 10px)'>
        <Typography sx={{ fontSize: '0.875rem', color: '#FFFFFFB2' }} variant='h6'>
          {translate('sidebar-digital-identity')}
        </Typography>
        <Grid item='true' sx={{ paddingTop: '2%', paddingLeft: floatingMenu ? '5px' : '0.5em' }}>
          <Button
            onClick={() => setIdentityAccordionOpen(!identityAccordionOpen)}
            fullWidth
            sx={{ color: '#FFFFFFB2', textTransform: 'none', justifyContent: 'left' }}>
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item='true'>
                <Grid container>
                  <Grid item='true' paddingRight='0.424rem' display='flex' alignItems='center'>
                    <Fingerprint />
                  </Grid>
                  <Grid item='true'>
                    <Typography sx={{ fontSize: '0.875rem', color: '#FFFFFFB2', width: 'max-content' }} variant='h6'>
                      {translate('sidebar-my-identity')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item='true' display='flex' justifyContent='flex-end'>
                {identityAccordionOpen ? <ExpandMoreIcon /> : <ChevronRightIcon fontSize={'small'}/>}
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Hider isHidden={!identityAccordionOpen && !floatingMenu}>
        <ButtonWithIcon
          text={translate('link-to-my-presentation-requests')}
          to={PAGE_ROUTES.EntityPresentationRequests}
          onNavigate={onNavigation}
          invalidateCaches={invalidateCaches}
          iconComponent={MyRequestsIcon}
          paddingLeft='2em'
        />
        <ButtonWithIcon
          text={translate('link-to-my-credential-requests')}
          to={PAGE_ROUTES.EntityCredentialRequests}
          onNavigate={onNavigation}
          invalidateCaches={invalidateCaches}
          iconComponent={MyCredentialRequestsIcon}
          paddingLeft='2em'
        />
        {/* <ButtonWithIcon
          text={translate('link-to-my-presentations')}
          to={PAGE_ROUTES.EntityPresentations}
          invalidateCaches={invalidateCaches}
          iconComponent={MyPresentationsIcon}
          paddingLeft='17%'
        /> */}
        <ButtonWithIcon
          text={translate('link-to-my-credentials')}
          to={PAGE_ROUTES.EntityCredentials}
          onNavigate={onNavigation}
          invalidateCaches={invalidateCaches}
          iconComponent={MyCredentialsIcon}
          paddingLeft='2em'
        />
        </Hider>
        <Grid item='true' sx={{ paddingTop: '2%', paddingLeft: floatingMenu ? '5px' : '0.5em' }}>
        <Button
          onClick={() => setAdminAccordionOpen(!adminAccordionOpen)}
          fullWidth
          sx={{ color: '#FFFFFFB2', textTransform: 'none', justifyContent: 'left' }}>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item='true'>
              <Grid container>
                <Grid item='true' paddingRight='0.424rem' display='flex' alignItems='center'>
                  <AdminActionsIcon />
                </Grid>
                <Grid item='true'>
                  <Typography sx={{ fontSize: '0.875rem', color: '#FFFFFFB2', width: 'max-content' }} variant='h6'>
                    {translate('sidebar-issuance')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item='true' display='flex' justifyContent='flex-end'>
              {adminAccordionOpen ? <ExpandMoreIcon /> : <ChevronRightIcon fontSize={'small'}/>}
            </Grid>
          </Grid>
        </Button>
        </Grid>
        <Hider isHidden={!adminAccordionOpen && !floatingMenu}>
          <ButtonWithIcon
            text={translate('link-to-requests-history')}
            to={PAGE_ROUTES.AdminPresentationRequestHistory}
            onNavigate={onNavigation}
            invalidateCaches={invalidateCaches}
            iconComponent={AdminRequestHistoryIcon}
            paddingLeft='2em'
          />
          <ButtonWithIcon
            text={translate('link-to-received-requests')}
            to={PAGE_ROUTES.AdminCredentialRequests}
            onNavigate={onNavigation}
            invalidateCaches={invalidateCaches}
            iconComponent={AdminCredentialRequestsIcon}
            paddingLeft='2em'
          />
          <ButtonWithIcon
            text={translate('link-to-issued-credentials')}
            to={PAGE_ROUTES.AdminCredentials}
            onNavigate={onNavigation}
            invalidateCaches={invalidateCaches}
            iconComponent={AdminCredentialsIcon}
            paddingLeft='2em'
          />
          <ButtonWithIcon
            text={translate('link-to-requests-library')}
            to={PAGE_ROUTES.AdminPresentationRequestTemplates}
            onNavigate={onNavigation}
            invalidateCaches={invalidateCaches}
            iconComponent={AdminRequestLibraryIcon}
            paddingLeft='2em'
          />
        </Hider>
      </Grid>
    {/* ---------------------------------------DIGITAL SIGNATURE------------------------------------------------------------------------ */}
      {/* <ButtonWithIcon
        text={translate('link-to-digital-assets')}
        to={PAGE_ROUTES.DigitalAssets}
        invalidateCaches={invalidateCaches}
        iconComponent={SpaceDashboardOutlinedIcon}
      /> */}
      <Hider isHidden={!config.INTERNAL.SHOW_DIGITAL_SIGNATURE}>
      {/* <Hider> */}
        <Grid id={'fourth-divider'} item='true' paddingBottom='5%' paddingTop='5%' paddingLeft='1em' paddingRight='1em'>
            <Divider sx={{ borderColor: '#FFFFFF33' }} />
          </Grid>
        
        <Grid  item='true' sx={{ color: '#FFFFFFB2'}} paddingLeft='calc(0.5em + 10px)'>
          <Tooltip title={translate('coming-soon-tooltip')} slotProps={{popper: {modifiers: [{name: 'offset',options: {offset: [-20, -18],},},],},}}>
            <Typography sx={{ fontSize: '0.875rem', color: '#FFFFFFB2' }} variant='h6'>
              {translate('digital-signature')}
            </Typography>
          </Tooltip>
        {/* ------------------------------SIGNATURE request buttonS------------------------------------------ */}
          <Grid item='true' sx={{ paddingTop: '2%', paddingLeft: floatingMenu ? '5px' : '0.5em' }}>
        {/* -----------------------------SIGNATURE request received button----------------------------------- */}
            <ButtonWithIcon
              id={'sidebar-digital-signature-requests-received'}
              text={translate('sidebar-digital-signature-requests-received')}
              to={PAGE_ROUTES.DigitalSignatureSignRequest}
              onNavigate={onNavigation}
              invalidateCaches={invalidateCaches}
              iconComponent={HistoryEduIcon}
              sx={{ paddingLeft: '1.5em' }}
              disabled
            />  

        {/* ---------------------------------SIGNATURE request sent button----------------------------------- */}
            <ButtonWithIcon
              id={'sidebar-digital-signature-Requests-Sent'}
              text={translate('sidebar-digital-signature-Requests-Sent')}
              to={PAGE_ROUTES.DigitalSignatureRequestsSent}
              onNavigate={onNavigation}
              invalidateCaches={invalidateCaches}
              iconComponent={AdminRequestHistoryIcon}
              sx={{ paddingLeft: '1.5em' }}
              disabled
            />  
        {/* -----------------------------------CATALOG/ button----------------------------------------------- */}
            <ButtonWithIcon
              id={'sidebar-digital-signature-documents'}
              text={translate('sidebar-digital-signature-documents')}
              to={PAGE_ROUTES.DigitalSignatureCatalog}
              onNavigate={onNavigation}
              invalidateCaches={invalidateCaches}
              iconComponent={CollectionsBookmarkIcon}
              sx={{ paddingLeft: '1.5em' }}
              disabled
            />  
        {/* -----------------------Signature documents FORMULARY button-------------------------------------- */}
            
            <ButtonWithIcon
              id={'sidebar-digital-signature-config-forms'}
              text={translate('sidebar-digital-signature-config-forms')}
              to={PAGE_ROUTES.DigitalSignatureForm}
              onNavigate={onNavigation}
              invalidateCaches={invalidateCaches}
              iconComponent={AdminRequestLibraryIcon}
              sx={{ paddingLeft: '1.5em' }}
              disabled
            />
              
          </Grid>
        </Grid>
        
        <Grid id={'fifth-divider'} item='true' paddingBottom='5%' paddingTop='5%' paddingLeft='1em' paddingRight='1em'>
          <Divider sx={{ borderColor: '#FFFFFF33' }} />
        </Grid>
        </Hider>
        <Grid item='true' sx={{ color: '#FFFFFFB2', paddingTop: '1.5%'}} paddingLeft='calc(0.5em + 10px)'>
          <Typography sx={{ fontSize: '0.875rem', color: '#FFFFFFB2', paddingBottom: '3%' }} variant='h6'>
            {translate('sidebar-contacts')}
          </Typography>
          <div style={{paddingLeft: '0.5em'}}>
            <ButtonWithIcon
              id={'link-to-corporate-contacts'}
              text={translate('link-to-corporate-contacts')}
              to={PAGE_ROUTES.ConctactsCorporate}
              onNavigate={onNavigation}
              invalidateCaches={invalidateCaches}
              iconComponent={ContactCorporateIcon}
              sx={{ paddingLeft: '1.5em' }}
            />
          <Hider isHidden={config.INTERNAL.THEME === 'b4e'}>
            <ButtonWithIcon
              id={'link-to-individual-contacts'}
              text={translate('link-to-individual-contacts')}
              to={PAGE_ROUTES.ContactsIndividuals}
              onNavigate={onNavigation}
              invalidateCaches={invalidateCaches}
              iconComponent={ContactIndividualIcon}
              sx={{ paddingLeft: '1.5em' }}
            />
          </Hider>
          </div>
        </Grid>
      
    </Grid>
  );
}
