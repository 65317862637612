import React from 'react'

import { useState } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';

// ------ FILE MODULES ---------------------------------------------------------
import {
    clearPresentationRequestTemplatesCache,
    fetchPRTAsync,
    selectPresentationRequestTemplates,
    selectPresentationRequestTemplatesCount,
    selectPresentationRequestTemplatesPage,
    selectPresentationRequestTemplatesRowsPerPage,
    selectPresentationRequestTemplatesState
} from '#reducers/presentationRequestTemplates';

import { REDUCER_STATUS } from '#constants';
import config from '#config';

import DigitalSignatureFormTable from '#privateComponents/Tables/DigitalSignatureFormTable.component';
import PresentationRequestTemplateFilters from '#privateComponents/Filters/SearchFilter.component';
import CreatePRTDetails from '#privateComponents/Popups/CreatePRTDetails.component';
import CreatePRTCredentialSelection from '#privateComponents/Popups/CreatePRTCredentialSelection.component';

import ViewsLayout from '../../../../views/Main/MainView.layout';
import CreateSignRequestFormDetails from './../../../../components/private/Popups/CreateSignRequestFormDetails.component';

export const SignForms = (props) => {

    const translate = props.translator;
    const layoutSize = props.layoutSize;

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [isCredentialSelectionOpen, setIsCredentialSelectionOpen] = useState(false);
    const [page, setPage] = useState(useSelector(selectPresentationRequestTemplatesPage));
    const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectPresentationRequestTemplatesRowsPerPage));
    const [search, setSearch] = useState(null);
    const [createDate, setCreateDate] = useState(null);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);

    const dispatch = useDispatch();

    const presentationTemplates = useSelector(selectPresentationRequestTemplates);
    const presentationsState = useSelector(selectPresentationRequestTemplatesState);
    const presentationCount = useSelector(selectPresentationRequestTemplatesCount);

    const isLoading = presentationsState === REDUCER_STATUS.LOADING;
    const needsReload = presentationsState === REDUCER_STATUS.NEEDS_REFRESH;

    if (needsReload) {
        dispatch(
            fetchPRTAsync({
                page,
                rowsPerPage,
                search,
                createDate: Math.round(createDate / 1000)
            })
        );
    }

    const onFilterSubmit = () => {
        setPage(0);
        dispatch(clearPresentationRequestTemplatesCache());
    };

    const onCancelOrExit = () => {
        setTitle(null);
        setDescription(null);
        setIsDetailsOpen(false);
        setIsCredentialSelectionOpen(false);
    };

    const handleSetPage = (newPage) => {
        setPage(newPage);
        dispatch(clearPresentationRequestTemplatesCache());
    };

    const handleSetRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        dispatch(clearPresentationRequestTemplatesCache());
    };

    return (
        <ViewsLayout
            pageTitle={translate('digital-signature-forms-page-title')}
            buttons={[
                {
                    // onClick: useLinkClickHandler('/mocks/signatureFormCreate'),
                    onClick: () => setIsDetailsOpen(true),
                    text: translate('digital-signature-forms-page-create-button'),
                },
                {
                    // onClick: useLinkClickHandler(`${config.constants.PAGE_ROUTES.AdminPresentationRequestCreation}`),
                    text: translate('digital-signature-forms-create-button'),
                },
            ]}
            layoutSize={layoutSize}
            >

            <CreateSignRequestFormDetails
                translate={translate}
                isOpen={isDetailsOpen}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                onCancelOrExit={onCancelOrExit}
                onNextClicked={() => {
                    setIsDetailsOpen(false);
                    setIsCredentialSelectionOpen(true);
                }}
            />

            <CreatePRTCredentialSelection
                translate={translate}
                isOpen={isCredentialSelectionOpen}
                onCancelOrExit={onCancelOrExit}
                title={title}
                description={description}
            />

            <Grid item='true'>
                <PresentationRequestTemplateFilters
                    translate={translate}
                    createDate={createDate}
                    setCreateDate={setCreateDate}
                    search={search}
                    setSearch={setSearch}
                    onFilterSubmit={onFilterSubmit}
                    refresh={onFilterSubmit}
                />
            </Grid>

            <DigitalSignatureFormTable
                translate={translate}
                isLoading={isLoading}
                presentationRequestTemplates={presentationTemplates}
                presentationCount={presentationCount}
                page={page}
                setPage={handleSetPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={handleSetRowsPerPage}
                hideFile
            />
        </ViewsLayout>
    );
}
